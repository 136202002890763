@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #8758ff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  text-align: center;
}

h1 {
  color: #fff;
  margin-bottom: 0.5rem;
  font-size: 1.75rem;
}

.TodoWrapper {
  background: #1A1A40;
  margin-top: 5rem;
  padding: 2rem;
  border-radius: 5px;
}

.TodoForm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  gap: .5rem;
}

.todo-input {
  outline: none;
  background: none;
  border: 1px solid #8758ff;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 300px;
  color: #fff;
}


.todo-input::placeholder {
  color: #ffffff4d;
}

.todo-btn {
  background: #8758ff;
  color: #fff;
  border: none;
  padding: 0.55rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

p {
  cursor: pointer;
  transition: all 0.3s ease;
}

p:hover {
  transform: translateY(-2px);
}

p:active {
  transform: translateY(1px);
}

.todo-btn:hover{
  background: #8f69f1;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.todo-btn:active{
  background: #7f4dff;
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.Todo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #8758ff;
  color: #fff;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.fa-trash {
  cursor: pointer;
  margin-left: 0.75rem;
  transition: all 0.3s ease;
}

.fa-trash:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.fa-trash:active {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}


.incompleted {
  color: #fff;
  cursor: pointer;
}

.completed {
  color: #c5aeff;
  text-decoration: line-through;
  cursor: pointer;
}

.fa-pen-to-square{
  cursor: pointer;
  transition: all 0.3s ease;
}

.fa-pen-to-square:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.fa-pen-to-square:active {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.edit-icon,
.delete-icon {
  cursor: pointer;
}

.todo-container {
  background: #1A1A40;
  margin-top: 5rem;
  padding: 2rem;
  border-radius: 5px;
}

.auth-container {
  background: #1A1A40;
  margin-top: 5rem;
  padding: 2rem;
  border-radius: 5px;
}

.auth-form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
}

.input-form {
  padding-left: .5rem;
  outline: none;
  background: none;
  border: 1px solid #8758ff;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 300px;
  color: #fff;
}

.button-form{
  background: #8758ff;
  color: #fff;
  border: none;
  padding: 0.55rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button-form:hover{
  background: #8f69f1;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.button-form:active{
  background: #7f4dff;
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 496px) {

  .auth-form {
    display: flex;
    justify-content: center;
    align-items: center
    ;
  }

  .button-form {
    font-size: .5rem;
  }
  
  .input-form {
    width: 235px;
    font-size: .5rem;
    
  }

  .todo-container {
    width: 25rem;
  }

  .h1-todo {
    font-size: 1.2rem;
  }

  .todo-btn {
    font-size: .8rem;
  }

  .todo-input {
    font-size: .8rem;
    width: 225px;
  }

}

@media screen and (max-width: 420px) {

  .auth-form {
    display: flex;
    justify-content: center;
    align-items: center
    ;
  }

  .h1-form {
    font-size: 1.4rem;
  }

  .button-form {
    font-size: .4rem;
  }
  
  .input-form {
    width: 175px;
    font-size: .4rem;
    
  }

  .auth-container {
    width: 20rem;
  }

  .todo-container {
    width: 20rem;
  }

  .h1-todo {
    font-size: 1.1rem;
  }

  .todo-btn {
    font-size: .6rem;
  }

  .todo-input {
    font-size: .6rem;
    width: 185px;
  }

}

@media screen and (max-width: 335px) {

  .auth-form {
    display: flex;
    justify-content: center;
    align-items: center
    ;
  }

  .h1-form {
    font-size: 1rem;
  }

  .button-form {
    font-size: .3rem;
  }
  
  .input-form {
    width: 155px;
    font-size: .3rem;
    
  }

  .auth-container {
    width: 18rem;
  }

  .todo-container {
    width: 17rem;
  }

  .h1-todo {
    font-size: 1rem;
  }

  .todo-btn {
    font-size: .4rem;
  }

  .todo-input {
    font-size: .4rem;
    width: 150px;
  }

}